import { __decorate } from "tslib";
import { Vue, Component, Watch } from 'vue-property-decorator';
import { H3Icon, H3Button } from '@h3/thinking-ui';
import { removeStorage } from '@/common/utils';
import store from '@/store';
import { OrgManageService, LoginService } from '@/sevices';
// import { MobileStaffSelector } from '@cloudpivot-hermes/lightning-ui';
let AppRecords = class AppRecords extends Vue {
    constructor() {
        super(...arguments);
        this.appLoading = false;
        this.teamNum = 1;
        // staffValue: any[] = [];
        /**
         * 当前选择的组织
         */
        this.curOrg = '';
    }
    showToast(show) {
        if (show) {
            this.$toast.show({
                icon: 'loading',
                text: '加载中',
            });
        }
        else {
            this.$toast.hide();
        }
    }
    /**
     * 获取用户姓名
     */
    get userInfo() {
        let publicUserInfo = localStorage.getItem('publicUserInfo');
        if (publicUserInfo) {
            publicUserInfo = JSON.parse(publicUserInfo) || {};
            const userInfo = publicUserInfo || {};
            return userInfo;
        }
        return {
            name: '-',
            mobile: '-',
        };
    }
    /**
     * 获取当前组织
     */
    async getOrgList() {
        const { data = [] } = await OrgManageService.getOrgList();
        let corpInfo = localStorage.getItem('corpInfo');
        if (corpInfo) {
            corpInfo = JSON.parse(corpInfo);
            this.curOrg = corpInfo.organizationName;
        }
        else {
            if (data) {
                this.curOrg = data[0]?.name;
            }
            else {
                this.curOrg = '';
            }
        }
        this.teamNum = data && data.length;
    }
    /**
     * 跳转切换组织页面
     */
    goToSwitchTeam() {
        if (this.teamNum > 1) {
            this.$router.push({
                name: 'm-work-platform-switch-team',
            });
        }
    }
    /**
     * 跳转修改密码页面
     */
    gotToChangePassword() {
        this.$router.push({
            name: 'm-work-platform-change-password',
            params: {
                mobile: this.userInfo.mobile,
            },
        });
    }
    async loginOut() {
        this.$modal.confirm({
            title: '',
            content: '确定要退出登录吗？',
            onConfirm: async () => {
                removeStorage(store);
                const res = await LoginService.logout();
                if (!res.success)
                    return;
                this.$router.push({
                    name: 'm-work-platform-login',
                });
            },
            onCancel() {
                // console.log('点击取消按钮');
            },
        });
    }
    async mounted() {
        window.document.title = '设置';
        this.appLoading = true;
        await this.getOrgList();
        this.appLoading = false;
    }
};
__decorate([
    Watch('appLoading')
], AppRecords.prototype, "showToast", null);
AppRecords = __decorate([
    Component({
        name: 'userCenter',
        components: {
            H3Icon,
            H3Button,
        },
    })
], AppRecords);
export default AppRecords;
